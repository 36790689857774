import React, {  } from 'react';
import SiteLayoutEmbeded from '../../components/SiteLayoutEmbeded';
import { VelgComponent } from '../velg';
import { Link } from '@reach/router';
import { Button } from 'antd';

// @ts-ignore
import {backbutton} from './embeded.module.scss';
import { ArrowLeftOutlined } from "@ant-design/icons";

function Index() {
    return (
        <SiteLayoutEmbeded>
            <VelgComponent isEmbeded={true} />
            <Link to="/embeded/"><Button icon={<ArrowLeftOutlined />} className={backbutton}>Tilbake</Button></Link>
        </SiteLayoutEmbeded>
    );
}

export default Index;
